<template>
  <ArticleList :type="SOLUTION.key" :uploadCoverImg="false" />
</template>

<script>
import ArticleList from "../../components/ArticleList.vue";
import { SOLUTION } from "@/constants/article";

export default {
  name: "solutionList",
  components: {
    ArticleList,
  },
  data() {
    return {
      SOLUTION,
    };
  },
};
</script>
